











































import { Component, Emit, Model, Prop, Vue, Watch } from "vue-property-decorator";
import Rules from "@/app/modules/_global/classes/Rules";
import moment from "moment";

@Component
export default class DatePicker extends Vue {
  @Prop(Array) readonly items!: object;
  @Prop(String) readonly label!: string;
  @Prop(Function) readonly allowedDates?: [];
  @Prop(String) readonly min?: string;
  @Prop(String) readonly max?: string;
  @Prop(Boolean) readonly dense?: boolean;
  @Prop({ type: String, default: "solo" }) readonly design?: string;

  @Model("input", { type: String }) readonly data!: string;

  @Emit()
  input() {
    return this.localData;
  }

  @Watch("localData")
  onLocalDataChanged() {
    this.input();
  }

  localData: string;
  modal = false;

  nowDate = moment().format("YYYY-MM-DD");

  rules = {
    required: Rules.required
  };

  constructor() {
    super();

    this.localData = this.data ?? "";
  }

  get formattedLocalData() {
    return this.localData ? moment(this.localData).format("DD.MM.YYYY") : null;
  }
}
